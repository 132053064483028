
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Detail extends Vue {
  private activeName = 'detail'

  get productId () {
    return this.$route.params.id || ''
  }

  created () {
    this.activeName = this.$route.path.substr(this.$route.path.lastIndexOf('/') + 1)
  }

  handleClick () {
    const path = `/productList/detail${this.productId}/${this.activeName}`
    if (path !== this.$route.path) {
      this.$router.replace({ path: path })
    }
  }
}
